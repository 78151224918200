import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './http'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入字体图标
// fontclass
import './assets/font/iconfont.css'
// Symbol
import './assets/font/iconfont'
import iconSvg from "./components/iconSvg/index";
import Vant from 'vant';
import 'vant/lib/index.css';


Vue.use(Vant);
import { Toast } from 'vant';
Vue.use(Toast);
Vue.component("iconSvg", iconSvg);
// document.addEventListener('contextmenu', e => e.preventDefault());
import { CountDown } from 'vant';
// css动画库
import 'animate.css';
Vue.use(CountDown);
// 弹框按钮
import {
  Message
} from 'element-ui';
Vue.prototype.$Message = Message

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = http

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
 