// 卡片管理模块
import request from '../utils/requrst'

// POST
export function postCustomerAdd(data) {
  return request({
    url: '/api/page_click/add',
    method: 'POST',
    responseType: "arraybuffer",
    data
  })
}

// 获取二维码
export function getQrcode(params) {
  return request({
    url: '/mini/qrcode/detail',
    method: 'get',
    params
  })
}
// 微信客服链接
export function getWxCsad(qrId) {
  return request({
    url: '/api/promotion/kfLink',
    method: 'post',
    params:qrId
  })
}
// 获取老师二维码

export function getWxCode(params) {
  return request({
    url: '/api/promotion/QIHU',
    method: 'post',
    params
  })
}
