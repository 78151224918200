<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="less">
html {
  height: 100%;

  body {
    padding: 0;
    margin: 0;
    height: 100%;

    #app {
      height: 100%;
    }
  }
}

* {
  padding: 0;
  margin: 0;
}
</style>

